import Scroll from './Scroll'
import { getQueryString } from '@shein/common-function'

let scrollTop = null
const cssString = `body.no-fullscreen .scroll-top__icon {
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

body.ipx-fullscreen .scroll-top__icon {
  margin-bottom: 34px;
}

.scroll-top__icon {
  box-sizing: content-box;
  display: none;
  position: fixed;
  right: 0.51rem;
  bottom: var(--scoll-to-top-bottom);
  width: .96rem;
  height: .96rem;
  line-height: .96rem;
  border-radius: 100%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: #767676;
  animation: fadeUp 0.3s;
  text-align: center;
  -webkit-appearance: none !important; /* stylelint-disable-line declaration-no-important */
}
[mir=rtl] .scroll-top__icon {
   left: 0.51rem;
   right: unset;
}
.scroll-top__icon [class*="iconfont"] {
  font-size: 20px;
}

.scroll-top__icon .c-up-numer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: .0533rem 0;
}

.scroll-top__icon .c-up-numer p {
  height: .32rem;
  line-height: .32rem;
}

.scroll-top__icon .c-up-numer span {
  display: inline-block;
  height: 1px;
  width: .6933rem;
  background-color: #666;
}
`

function createCommonEl (tagName = 'div', configAttribute = {}) {
  const el = document.createElement(tagName)
  Object.keys(configAttribute).forEach(key => {
    el.setAttribute(key, configAttribute[key])
  })
  return el
}
class EventScroll {
  constructor() {
    this.scrollCallback = null
    this.viewNumCallback = null
    this.showFlag = false
  }

  scroll(fn) {
    this.scrollCallback = fn
  }

  emit(fn) {
    this.viewNumCallback = fn
  }

  clear() {
    if (scrollTop) {
      scrollTop.clear()
      // 曝光相关的状态hasBeenReported重置
      scrollTop.hasBeenReported = false
    }
    this.viewNumCallback = null
  }
  show() {
    scrollTop?.show()
  }
  hide() {
    scrollTop?.hide()
  }
}

const eventScroll = new EventScroll()

class ScrollTop {
  constructor() {
    this.hasBeenReported = false         // 上报事件执行一次 
    this.currentScrollTop = 0            //  滚动高度     
    this.visible = true                  // 是否显示    
    this.el = this.createDiv()
    this.iconEl = createCommonEl('i', { 'aria-hidden': true, class: 'iconfont icon-top1' })
    this.numberEl = null                 // 浏览深度数量div
    this.init()
  }

  init() {
    const bodyEl = document.querySelector('body')
    this.el.appendChild(this.iconEl)
    bodyEl.appendChild(this.el)
    this.handleScroll()
    this.addEventListener()
  }

  addEventListener() {
    this.el.addEventListener('click', () => {
      if (!this.visible) return
      const top = +getQueryString({ key: 'scroll_top_x' }) || 0
      window.scrollTo(0, top + 1)
      // ios 头部状态栏需要两个动作才能展开
      setTimeout(() => {
        window.scrollTo(0, top)

        // ios部分机型会出现置顶后又回弹的问题，延时重新置顶
        setTimeout(() => {
          if (top === 0 && window.scrollY > 0) {
            window.scrollTo(0, 0)
          }
        }, 50)
        
      }, 100)
      this.el.style.display = 'none'
      this.clickAnalysis()
    })
  }

  clickAnalysis() {
    // if (window.PageGroup == '商品详情页') {  // 目前仅商品详情页上报此事件 @余凯莉
    //   ga && GB_ANALYSIS_GA_SEND && ga(GB_ANALYSIS_GA_SEND, {
    //     hitType: 'event',
    //     eventCategory: '商品详情页',
    //     eventAction: 'ClickBackToTop'
    //   })
    // }
    sa('send', {
      activity_name: 'click_back_to_top'
    })
  }

  exposeBacktotop() {
    if (this.hasBeenReported) return false
    sa('send', {
      activity_name: 'expose_backtotop'
    })
    this.hasBeenReported = true
  }

  handleScroll() {
    new Scroll({ delay: 20, callback: ({ scrollTop, type }) => {
      if (!this.visible) return
      eventScroll.scrollCallback?.({ scrollTop, type })
      this.currentScrollTop = scrollTop
      if (this.currentScrollTop >= window.innerHeight * 1) {
        if (!this.showFlag) {
          const footerSelectPayEl = document.getElementById('footer-select-pay')
          const quickRegisterEl = document.querySelector('.j-quick-register')
          if (footerSelectPayEl || quickRegisterEl) return // 存在支付方式楼层置底不展示
          this.showFlag = true
          document.querySelector('body').classList.add('show-scroll-top')
          this.el.style.display = 'block'
          this.exposeBacktotop()
        }
        this.viewElNumber()
      } else {
        if (this.showFlag) {
          this.showFlag = false
          document.querySelector('body').classList.remove('show-scroll-top')
          this.el.style.display = 'none'
        }

        this.iconEl.style.display = 'block'
      }
    } })
  }

  viewElNumber() {
    const { total, number } = eventScroll.viewNumCallback?.() || {}

    const resetStyle = () => {
      if (!this.numberEl) return
      this.iconEl.style.display = 'block'
      this.numberEl.style.display = 'none'
    }

    if (!total || !number || total && number == total) {
      resetStyle()
      return
    }

    if (!this.numberEl) {
      this.numberEl = createCommonEl('div', {
        class: 'c-up-numer'
      })
      this.el.appendChild(this.numberEl)
    }

    this.numberEl.innerHTML = `
    <p>${number}</p>
    <span></span>
    <p>${total}</p>
    `
    this.numberEl.style.display = 'flex'
    this.iconEl.style.display = 'none'

    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      const bodyTop = Math.abs(document.body.style?.top?.replace('px', ''))
      const stopScrollTop = bodyTop || document.body.scrollTop || document.documentElement.scrollTop
      if(stopScrollTop && stopScrollTop == this.currentScrollTop){
        resetStyle()
      }
    }, 500)
  }
  createStyle(){
    const el = document?.createElement('style')
    el.type = 'text/css'
    // 将样式内容插入到 <style> 标签中
    if (el.styleSheet) {
      // 兼容 IE
      el.styleSheet.cssText = cssString
    } else {
      el?.appendChild(document?.createTextNode(cssString))
    }
    document?.head?.appendChild(el)
  }
  createDiv() {
    const { language = {} } = window?.gbCommonInfo || {}
    // --low-stock-banner-bottom-gap 为商详页低库存banner的底部间距
    const backEl = createCommonEl('div', {
      tabindex: 0,
      'aria-label': language.SHEIN_KEY_PWA_15051,
      role: language.SHEIN_KEY_PWA_17952,
      class: 'scroll-top__icon j-uptt mshe-z-returnTop',
      style: 'margin-bottom: calc(var(--low-stock-banner-bottom-gap, 0px) + env(safe-area-inset-bottom))',
    })
    this.createStyle()
    return backEl
  }

  clear() {
    this.currentScrollTop = 0
  }

  show() {
    this.visible = true
    this.el.style.display = 'block'
  }
  hide() {
    this.visible = false
    this.el.style.display = 'none'
  }
}

if (typeof window !== 'undefined') {
  requestIdleCallback(() => {
    scrollTop = new ScrollTop()
  })
}

export default eventScroll


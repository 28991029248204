import TMG from '@shein/time-management-guru'
import { getPageData, getLandingPageData } from 'public/src/pages/trend/api/index.js'

function generateParams(to) {
  const { contentCarrierId_adp = '', cccCatId = '', src_tab_page_id = '' } = to.query
  return {
    contentCarrierId_adp: String(contentCarrierId_adp),
    cccCatId: String(cccCatId),
    src_tab_page_id,
  }
}

function generateLandingParams(to) {
  const { trendId = '', selectId = '', selectUrlId = '', adp = '', cccCatId = '', catId = '', channelId = '', src_tab_page_id = '' } = to.query
  return {
    trendId: String(trendId),
    selectId: String(selectId),
    selectUrlId: String(selectUrlId),
    adp: String(adp),
    cccCatId: String(cccCatId),
    catId: String(catId),
    channelId: String(channelId),
    src_tab_page_id,
  }
}

// 触发趋势 home页 render数据
export const TMGTriggerForRenderPage = (to) => {
  TMG.triggerQuickRequests('trend', {
    quickRequestNames: ['getTrendPageData'],
    throttle: true,
    triggerParams: generateParams(to)
  })
}

// 触发趋势 landing页 render数据
export const TMGTriggerForLandingRenderPage = (to) => {
  TMG.triggerQuickRequests('trend', {
    quickRequestNames: ['getTrendLandingPageData'],
    throttle: true,
    triggerParams: generateLandingParams(to)
  })
}

// 获取趋势 home页 render数据
export const getTmgTrendPageData = async (params = {}) => {
  return await TMG.useQuickRequest(`trend/getTrendPageData`, params)
}
// 获取趋势 landing页 render数据
export const getTmgTrendLandingPageData = async (params = {}) => {
  return await TMG.useQuickRequest(`trend/getTrendLandingPageData`, params)
}

// 趋势频道模块的预请求
export const trendRequests = {
  quickRequests: {
    getTrendPageData: {
      params: ({ triggerParams }) => {
        return triggerParams
      },
      request: getPageData,
    },
    getTrendLandingPageData: {
      params: ({ triggerParams }) => {
        return triggerParams
      },
      request: getLandingPageData,
    },
  },
}

import { prefetchResource } from '../../../services/prefetchResource'
export const loadDetailComponent = () => {
  return import(
    /* webpackChunkName: "goods_detail" */ '../../goods_detail_v2/container.vue'
  )
}
export const detailRouters = [
  {
    // path: '/*-p-(\\d+)-cat-(\\d+).html',
    path: '/:langPath?/:goodsName(.+)-p-:goodsId(\\d+)-cat-:catId(\\d+).html',
    component: prefetchResource.importAsyncComponent({
      chunkName: 'goods_detail',
      componentFactory: () => loadDetailComponent()
    }),
    name: 'page_goods_detail',
    meta: {
      scrollTop: true,
      pageType: 'productDetail',
      isCssInVue: true,
      monitorTag: 'product-detail',
    },
    alias: [
      '/:langPath?/:goodsName(.+)-p-:goodsId(\\d+).html',
      '/:langPath?/:goodsName(.+)-p-:goodsId(\\d+)-cat-:catId(|).html'
    ],
  },
  // {
  //   path: '/*-p-(\\d+).html',
  //   component: prefetchResource.importAsyncComponent({
  //     chunkName: 'goods_detail',
  //     componentFactory: () => loadDetailComponent()
  //   }),
  //   name: 'page_goods_detail',
  //   meta: {
  //     scrollTop: true,
  //     isCssInVue: true,
  //     pageType: 'productDetail',
  //   },
  // },
  // {
  //   path: '/*-p-(\\d+)-cat-(|).html',
  //   component: prefetchResource.importAsyncComponent({
  //     chunkName: 'goods_detail',
  //     componentFactory: () => loadDetailComponent()
  //   }),
  //   name: 'page_goods_detail',
  //   meta: {
  //     scrollTop: true,
  //     pageType: 'productDetail',
  //     isCssInVue: true,
  //     monitorTag: 'product-detail',
  //   }
  // },
]

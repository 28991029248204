
let deeplinkElement = null

let timer = null

export const changeDeepLinkElement = (route) => {
  requestIdleCallback(() => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      if (typeof window === 'undefined' || !route) return
      const LOCATION = window.location
      let { params = {} } = route
      let name = window.SaPageInfo?.page_name
      const client = gbCommonInfo.WEB_CLIENT
      const data = {}
    
      if (/\/wishlist/.test(LOCATION.href)) {
        name = 'wishlist'
      } else if (/\/user\/support/.test(LOCATION.href)) {
        name = 'user_support'
      } else if (/daily-new/.test(LOCATION.href)) {
        name = 'dalay_new'
      } else if (/shein-picks/.test(LOCATION.href)) {
        name = 'shein_picks'
      }
    
      if (route.query) {
        if (['page_real_class', 'page_select_class'].includes(name)) {
          data.category_id = params.catId || params.scId // @Notice Vue 3 upgrade: 待二次验证
          if (route.query.attr_ids) data.attr_ids = route.query.attr_ids
        } else if (name === 'wishlistGroupShare') {
          data.group_id_token = route.query.group_id_token
          data.group_id = route.query.group_id
          data.member_id = route.query.member_id
          data.share_url = `https://api-shein.shein.com/h5/sharejump/wishlistgroupshare?group_id_token=${route.query.group_id_token}&group_id=${route.query.group_id}&member_id=${route.query.member_id}&language=${route.query.language}`
          data.img_url = route.query.img_url
          data.share_img_url = route.query.img_url
          data.description = route.query.description
        } else if (name === 'page_goods_detail') {
          data.goods_id = params.goodsId
        } else if (name === 'config_activity') {
          data.url = `https://api-${client}.${client}.com/h5/campaign/${encodeURIComponent(
            params.name_en
          )}`
        }
        if (route.query.url_from) data.url_from = route.query.url_from
        if (route.query.utm_campaign) data.utm_campaign = route.query.utm_campaign
        if (route.query.sclid) data.sclid = route.query.sclid
      }
    
      const deeplink = {
        romwe: {
          page_home: `${client}link://applink/wakeup`,
          page_goods_detail: `${client}link://applink/goods`,
          page_flash_sale: `${client}link://applink/lightning-deals`,
          page_real_class: `${client}link://applink/category`,
          page_select_class: `${client}link://applink/selectcategory`,
          config_activity: `${client}link://applink/pushtoweb`,
          shein_picks: `${client}link://applink/promo-discount`,
          robot: `${client}link://applink/robot`,
          cart: `${client}link://applink/cart`,
          wishlist: `${client}link://applink/wishlist`,
          user_support: `${client}link://applink/support`,
          dalay_new: `${client}link://applink/daily-new`,
          page_order_list: `${client}link://applink/order-list`,
          page_login: `${client}link://applink/login`,
        },
        shein: {
          page_home: `${client}link://applink/wakeup`,
          page_goods_detail: `${client}link://applink/goods`,
          page_real_class: `${client}link://applink/category`,
          page_select_class: `${client}link://applink/selectcategory`,
          config_activity: `${client}link://applink/pushtoweb2`,
          page_flash_sale: `${client}link://applink/flashsale`,
          shein_picks: `${client}link://applink/shein-picks`,
          wishlistGroupShare: route.query
            ? `${client}link://applink/wish_group_detail`
            : '',
          page_live_chat: `${client}link://applink/robot`,
          page_cart: `${client}link://applink/cart`,
          wishlist: `${client}link://applink/wishlist`,
          user_support: `${client}link://applink/support`,
          dalay_new: `${client}link://applink/daily-new`,
          page_order_list: `${client}link://applink/order-list`,
          page_login: `${client}link://applink/login`,
        },
      }
    
      const urls = {
        romwe: {
          config_activity: `https://api-romwe.romwe.com/h5/campaign/${encodeURIComponent(
            params.name_en
          )}`,
        },
        shein: {
          config_activity: `https://api-shein.shein.com/h5/campaign/${encodeURIComponent(
            params.name_en
          )}`,
        },
      }
      let metaDataKey = ''
      switch (name) {
        case 'page_home':
        case 'page_flash_sale':
        case 'shein_picks':
        case 'wishlistGroupShare':
          metaDataKey = ''
          break
        case 'page_goods_detail':
          metaDataKey = 'goods_id'
          break
        case 'page_real_class':
        case 'page_select_class':
          metaDataKey = 'category_id'
          break
        case 'config_activity':
          metaDataKey = 'url'
          break
        default:
          return ''
      }
      if (metaDataKey === 'url') {
        data.url = urls[client][name]
      } else if (metaDataKey) {
        if (
          metaDataKey === 'page_real_class' ||
          metaDataKey === 'page_real_class'
        ) {
          const arrtids = (route.query && route.query.attr_ids) || null
          if (!arrtids) {
            data[metaDataKey] = params.catId // @Notice Vue 3 upgrade: 待二次验证
          }
        } else {
          if (params.catId) { // @Notice Vue 3 upgrade: 待二次验证
            data[metaDataKey] = params.catId
          }
        }
      }
    
      const contentString = deeplink[client][name] +
        (Object.keys(data).length > 0
          ? '?data=' + encodeURIComponent(JSON.stringify(data))
          : '')
    
        
      if (!deeplinkElement) {
        deeplinkElement = document.createElement('meta')
        deeplinkElement.setAttribute('name', `branch:deeplink:${client}link`)
        deeplinkElement.setAttribute('content', contentString)
        document.head.appendChild(deeplinkElement)
      } else {
        deeplinkElement.setAttribute('content', contentString)
      }
    }, 500)
  })
}

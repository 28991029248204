import schttp from 'public/src/services/schttp'
import TMG from '@shein/time-management-guru'
import { isLogin } from 'public/src/pages/common/utils/index.js' 
import { getUserAbtData } from '@shein-aidc/basis-abt-router' 

const TIME5 = 1000 * 60 * 5
const TIME3 = 1000 * 60 * 3


// 获取token数据
export const getImTokenMasterData = async (params) => {
  return await TMG.useQuickRequest(
    `robot/getImToken`,
    params,
    { onlyFirstUseQuick: true }
  )
}

// 获取bot init数据
export const getPreBotInitData = async (params) => {
  return await TMG.useQuickRequest(
    `robot/getRobotInit`,
    params,
    { onlyFirstUseQuick: true }
  )
}

// 获取language数据
export const getRobotLanguageData = async (params = {}) => {
  return await TMG.useQuickRequest(`robot/getRobotLanguage`, params)
}

// 获取abt数据
export const getRobotAbtData = async (params = {}) => {
  return await TMG.useQuickRequest(`robot/getRobotAbt`, params)
}

export const TMGTriggerForImToken = (to) => {
  // getImToken 此处需要预请求，pwa跳转不和getRobotInit做绑定会导致推送消息漏推情况

  TMG.triggerQuickRequests('robot', {
    // quickRequestNames: ['getRobotLanguage', 'getRobotAbt', 'getRobotInit'],
    quickRequestNames: ['getImToken', 'getRobotLanguage', 'getRobotAbt', 'getRobotInit'],
    throttle: true,
    triggerParams: {
      to
    }
  })
}

// 注册机器人预请求
export const robotRequests = {
  quickRequests: {
    getImToken: {
      params: () => {
        return { email: '' }
      },
      request: async () => {
        // 未登录不需要获取token
        if (!isLogin()) return {}

        return await schttp({
          url: '/api/robot/cschat/token/get',
          method: 'POST',
          params: {
            email: ''
          }
        }).catch(e => {
          console.error(e)
        })
      },
    },
    getRobotLanguage: {
      params: () => {
        return { page: 'pre_online_help' }
      },
      request: async () => {
        return await schttp({
          url: '/api/common/language/get',
          params: {
            page: 'pre_online_help'
          }
        }).catch(e => {
          console.error(e)
        })
      },
      // 设置会话缓存过期时间
      sessionExpired: TIME3
    },
    getRobotAbt: {
      params: () => {
        return {}
      },
      request: async () => {
        return await getUserAbtData()
      },
      // 设置会话缓存过期时间
      sessionExpired: TIME5
    },
    getRobotInit: {
      params: ({ triggerParams }) => {
        return triggerParams.to?.query || {}
      },
      request: async (params) => {
        return await schttp({
          url: '/api/robot/common/botInitMessage/query',
          params,
        }).catch(e => {
          console.error(e)
        })
      },
      // 设置会话缓存过期时间
      sessionExpired: TIME3
    },
  }
}

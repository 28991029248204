import { createApp } from 'vue'
import mitt from 'mitt'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { changeDeepLinkElement } from 'public/src/pages/components/journey-branch/deeplinkElement.js'

const NO_BRANCH_ROUTES = [
  /\/pay\/result\/success/,
  /\/super-deals/,
]

export function createBranchVariable() {
  window.vBus = window.vBus || mitt()
  const JOURNEY_BRANCH = {
    close: true,
    abtBranch: 0,
    loginDialogShow: false,
    offsetHeight: 0,
    pageView(vm) {
      try {
        changeDeepLinkElement(vm)
      } catch (e) { console.log(e) }
    }
  }
  window.JOURNEY_BRANCH = window.JOURNEY_BRANCH || JOURNEY_BRANCH
  return window.JOURNEY_BRANCH
}

async function validateBranch() {
  const u = navigator.userAgent
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 // android
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // iOS

  if (!(isAndroid || isiOS)) return
  if (NO_BRANCH_ROUTES.some(route => route.test(location.href))) return
  const { Branch = {} } = await getUserAbtData()
  window.JOURNEY_BRANCH.abtBranch = Branch?.param || 0
  return !!window.JOURNEY_BRANCH.abtBranch
}

// app页使用
export const appValidBranch = async () => {
  return await validateBranch()
}

// 游戏页面调用
export const createBranch = () => {
  const renderJourneyBranch = async () => {
    const type = await validateBranch()
    if (!type) return

    const el = document.createElement('div')
    try {
      document.body.append(el)
    } catch (e) {
      document.body.appendChild(el)
    }
    const compoment = await import('public/src/pages/components/journey-branch')
    const app = createApp(compoment.default)
    app.mount(el)
  }
  if (document.readyState === 'complete') {
    setTimeout(renderJourneyBranch, 0)
  } else {
    window.addEventListener('load', renderJourneyBranch)
  }  
} 
